import { Navigation, Header , About , Contact , Blog , Testimonials, Services } from "./devecua/index";
import JsonData from "../data/data.json";
import { useEffect, useState } from "react";

export const Sitio = (props) => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {  setLandingPageData(JsonData);  }, []);

    return (
       <div>
            <Navigation />
            <Header data={landingPageData.Header} />
            <About data={landingPageData.About} />
            <Services data={landingPageData.Services} />
            <Blog  />
            <Testimonials data={landingPageData.Testimonials} />
            <Contact data={landingPageData.Contact} asunto={"Contacto Devecua"} />
        </div>
    )
  }