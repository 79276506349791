import { useState } from 'react'

const initialState = {
  name: '',
  email: '',
  phone: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message , phone}, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    //console.log(name, email, message)
    const url = 'https://api-sri-efactura.devecua.com/api/sri/sendmail/info';
    const subject ="Formulario de Contacto - Sitio DEVECUA";
    const body = `Mensaje: \nCorreo: ${email}\n Nombre: ${name}\n Telefono: ${phone}\n Mensaje: ${message}`;
    //console.log('Mensaje: '+body);

    fetch(url , {
        method: 'POST', 
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json",
          "Accept" : 'application/json',
          "Access-Control-Request-Headers": "*",
          "Access-Control-Request-Method": "*",
          "Access-Control-Allow-Origin" : "*"
       },
        body: JSON.stringify(
          {
            subject: subject,
            message: body
         }
         )
      })
      .then(data => {
        console.log('Correo Enviado....'+data.status);
        alert('Correo Enviado, Muchas gracias estaremos muy rapido en contacto');
        clearState();
      })
      .catch((error) => {
        console.error('Error Sendmail Devecua :', error);
      });
     
  }

 
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Ponerse en Contacto</h2>
                <p>
                Complete el siguiente formulario para enviarnos un correo electrónico y nos pondremos en contacto con usted lo antes posible.
                </p>
              </div>
              <form name='sentMessage' validate="true" onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Nombre'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='phone'
                        name='phone'
                        className='form-control'
                        placeholder='Telefono'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Mensaje'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Enviar Mensaje
                </button>
              </form>
            </div>
          </div>


          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Informacion de Contacto</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Direccion
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-whatsapp  '></i> Comunicacion solo por WhatsApp
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2023 Devecua Informix Ecuador. 
          </p>
        </div>
      </div>
    </div>
  )
}
