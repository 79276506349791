export const Blog = (props) => {
  return (
    <div id='blog' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Blog/Tutoriales en Informix Server</h2>
        </div>
        <div className='row'>
          <div  className='col-md-12'>
          <h3>Canal Youtube Hablemos de Informix</h3>
          <div className="embed-responsive embed-responsive-16by9">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/_yfHKPNDVkU" title="YouTube video player"   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  ></iframe>
          </div>
          </div>
         
         
        </div>
      </div>
    </div>
  )
}
