import  { Meta , Header , Services, Contact , Footer, Pricing  } from "./eone/index";
export const Eone = (props) => {
  return (
    <div>
        <Meta/>
        <Header />
        <Services />
        <Pricing />
        <Contact />
        <Footer />
    </div>
  )
};
