import { useState } from 'react'

const initialState = {
  name: '',
  email: '',
  phone: '',
  message: '',
}
export const Contact = (props) => {
    const [{ name, email, message , phone}, setState] = useState(initialState)

    const handleChange = (e) => {
      const { name, value } = e.target
      setState((prevState) => ({ ...prevState, [name]: value }))
    }
  
    const clearState = () => setState({ ...initialState })
  
    const handleSubmit = (e) => {
      e.preventDefault()
      //console.log(name, email, message)
      const url = 'https://api-sri-efactura.devecua.com/api/sri/sendmail/info';
      const subject ="Formulario de Contacto - Sitio EONE";
      const body = `Mensaje: \nCorreo: ${email}\n Nombre: ${name}\n Telefono: ${phone}\n Mensaje: ${message}`;
      //console.log('Mensaje: '+body);
  
      fetch(url , {
          method: 'POST', 
          mode: 'cors', // no-cors, *cors, same-origin
          headers: {
            "Content-Type": "application/json",
            "Accept" : 'application/json',
            "Access-Control-Request-Headers": "*",
            "Access-Control-Request-Method": "*",
            "Access-Control-Allow-Origin" : "*"
         },
          body: JSON.stringify(
            {
              subject: subject,
              message: body
           }
           )
        })
        .then(data => {
          console.log('Correo Enviado....'+data.status);
          alert('Correo Enviado, Muchas gracias estaremos muy rapido en contacto');
          clearState();
        })
        .catch((error) => {
          console.error('Error Sendmail Devecua :', error);
        });
       
    }

  return (
    <div id="contact">
      
    <section id="contact-us">
        <div class="container">
            <div class="section-header">
                <h2 class="section-title wow fadeInDown">Contactanos</h2>
                <p class="wow fadeInDown">Un ejecutivo de ventas se contactara inmediatamente con usted.</p>
            </div>
        </div>
    </section>

    <section id="contact">
        <div class="container">
            <div class="container contact-info">
                <div class="row">
				  <div class="col-sm-4 col-md-4">
                        <div class="contact-form">
                            <h3>Informacion</h3>

                            <address>
                              <strong>Devecua Software Ecuador S.A.S</strong><br/>
                              Guayaquil-Ecuador<br/>
                              (+593) 09 88903836<br/>
							  info@devecua.com
                            </address>
					</div>
          </div>
                    <div class="col-sm-8 col-md-8">
                        <div class="contact-form">
                       
                            <form id="main-contact-form" name="contact-form" method="post" validate="true" onSubmit={handleSubmit}>
                                <div class="form-group">
                                    <input type="text" name="name" class="form-control" placeholder="Nombre" required     onChange={handleChange} />
                                </div>
                                <div class="form-group">
                                    <input type="email" name="email" class="form-control" placeholder="Email" required     onChange={handleChange}/>
                                </div>
                                <div class="form-group">
                                    <input type="text" name="phone" class="form-control" placeholder="Telefono" required    onChange={handleChange}/>
                                </div>
                                <div class="form-group">
                                    <textarea name="message" class="form-control" rows="8" placeholder="Mensaje" required     onChange={handleChange} ></textarea>
                                </div>
                                <button type="submit" class="btn btn-primary">Enviar Mensaje</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
   </section>

    </div>
  );
};
