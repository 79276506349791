import SmoothScroll from "smooth-scroll";
import { BrowserRouter as Router, Route, Routes  } from "react-router-dom";
import { Sitio } from "./components/sitio";
import { Eone } from "./components/eone";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <Router>
      <Routes>
        <Route  path='/'      element={<Sitio/>} />
        <Route  path='/eone'  element={<Eone/>} />
      </Routes>
    </Router>
  );
};

export default App;
