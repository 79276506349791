
export const Pricing = (props) => {
  return (
    <section id="pricing">
        <div class="container">
            <div class="section-header">
                <h2 class="section-title wow fadeInDown">Planes</h2>
                <p class="wow fadeInDown">Todos nuestros planes incluyen el IVA  <br/> Soporte en horario laboral (Lunes a Viernes).</p>
            </div>

            <div class="row">

                <div class="col-md-4 col-sm-6 col-xs-12">
                    <div class="wow zoomIn" data-wow-duration="400ms" data-wow-delay="0ms">
                        <ul class="pricing">
                            <li class="plan-header">
                                <div class="price-duration">
                                    <span class="price">
                                        $ 60
                                    </span>
                                    <span class="duration">
                                        1 Pago Anual / $5 x Mes
                                    </span>
                                </div>

                                <div class="plan-name">
                                    Plan Personal
                                </div>
                            </li>
                            <li><strong> Hasta 250 </strong>DOCUMENTOS ELECTRONICOS</li>
                            <li class="plan-purchase"><a class="btn btn-primary" href="#contact">Contactar!</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6 col-xs-12">
                    <div class="wow zoomIn" data-wow-duration="400ms" data-wow-delay="200ms">
                        <ul class="pricing featured">
                            <li class="plan-header">
                                <div class="price-duration">
                                    <span class="price">
                                        $ 84
                                    </span>
                                    <span class="duration">
                                        1 Pago Anual / $7 x Mes
                                    </span>
                                </div>

                                <div class="plan-name">
                                    Plan Profesional
                                </div>
                            </li>
                            <li><strong>Hasta 600</strong> DOCUMENTOS ELECTRONICOS</li>
                            <li class="plan-purchase"><a class="btn btn-primary" href="#contact">Contactar!</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6 col-xs-12">
                    <div class="wow zoomIn" data-wow-duration="400ms" data-wow-delay="400ms">
                        <ul class="pricing">
                            <li class="plan-header">
                                <div class="price-duration">
                                    <span class="price">
                                        $ 120 
                                    </span>
                                    <span class="duration">
                                        1 Pago Anual / $10 x Mes
                                    </span>
                                </div>

                                <div class="plan-name">
                                    Plan Negocio
                                </div>
                            </li>
                            <li><strong>Hasta 1500</strong> DOCUMENTOS ELECTRONICOS</li>
                            <li class="plan-purchase"><a class="btn btn-primary" href="#contact">Contactar!</a></li>
                        </ul>
                    </div>
                </div>


            </div>
        </div>
    </section>
  );
};
